/* eslint-disable import/no-extraneous-dependencies */
import {
  ICodingRequestBody,
  IConversationRequestBody,
} from 'src/types/copilot';
import SupersetClient from './SupersetClient';
import { DEFAULT_BASE_URL, DEFAULT_FETCH_RETRY_OPTIONS } from './constants';
import {
  ClientConfig,
  ClientTimeout,
  Credentials,
  CsrfPromise,
  CsrfToken,
  FetchRetryOptions,
  Headers,
  Host,
  Mode,
  Protocol,
} from './types';

/* eslint-disable prettier/prettier */
const defaultUnauthorizedHandler = () => {
if (!window.location.pathname.startsWith('/login')) {
    window.location.href = `/login?next=${window.location.href}`;
}
};

export default class WukongClientClass {
  credentials: Credentials;

  csrfToken?: CsrfToken;

  csrfPromise?: CsrfPromise;

  guestToken?: string;

  guestTokenHeaderName: string;

  fetchRetryOptions?: FetchRetryOptions;

  baseUrl: string;

  protocol: Protocol;

  host: Host;

  headers: Headers;

  mode: Mode;

  timeout: ClientTimeout;

  handleUnauthorized: () => void;

  databaseId = 1;

  allDatabaseIds: number[] = [];

  constructor({
    baseUrl = DEFAULT_BASE_URL,
    host,
    protocol,
    headers = {},
    fetchRetryOptions = {},
    mode = 'same-origin',
    timeout,
    credentials = undefined,
    csrfToken = undefined,
    guestToken = undefined,
    guestTokenHeaderName = 'X-GuestToken',
    unauthorizedHandler = defaultUnauthorizedHandler }: ClientConfig = {}) {
    const url = new URL(
    host || protocol
        ? `${protocol || 'https:'}//${host || 'localhost'}`
        : baseUrl,
    // baseUrl for API could also be relative, so we provide current location.href
    // as the base of baseUrl
    window.location.href,
    );
    url.port = '5000';
    this.baseUrl = url.href.replace(/\/+$/, ''); // always strip trailing slash
    this.host = url.host;
    this.protocol = url.protocol as Protocol;
    this.headers = { Accept: 'application/json', ...headers }; // defaulting accept to json
    this.mode = mode;
    this.timeout = timeout;
    this.credentials = credentials;
    this.csrfToken = csrfToken;
    this.guestToken = guestToken;
    this.guestTokenHeaderName = guestTokenHeaderName;
    this.fetchRetryOptions = {
    ...DEFAULT_FETCH_RETRY_OPTIONS,
    ...fetchRetryOptions,
    };
    if (typeof this.csrfToken === 'string') {
    this.headers = { ...this.headers, 'X-CSRFToken': this.csrfToken };
    this.csrfPromise = Promise.resolve(this.csrfToken);
    }
    if (guestToken) {
    this.headers[guestTokenHeaderName] = guestToken;
    }
    this.handleUnauthorized = unauthorizedHandler;

    // get default database_id we use for Wukong
    SupersetClient.get({
    endpoint: `/api/v1/database/?q=(order_column:changed_on_delta_humanized,order_direction:desc,page:0,page_size:25)`,
    headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.response.ok) {
      throw new Error(
          `SupersetCient Request failed with status: ${response.response.status}`
      );
      }
      return response.json;
    })
    .then((result: { ids: number[] }) => {
      if (result.ids && result.ids.length > 0) {
      this.databaseId = result.ids[0];
      if (result.ids.indexOf(25) === -1) {
          this.allDatabaseIds.push(25);
      }
      this.allDatabaseIds.push(...result.ids);
      } else {
      throw new Error(
          `SupersetCient Request failed to get the default database id`
      );
      }
    })
  }

  refactor(requestBody: ICodingRequestBody) {
    return SupersetClient.post({
      endpoint: `/api/v1/sqllab/copilot_sql_refactor/`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json);
  }


  generate(requestBody: ICodingRequestBody) {
    return SupersetClient.post({
      endpoint: `/api/v1/sqllab/copilot_sql_generate/`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json);
  }

  getConversationResponse(
    requestBody: IConversationRequestBody,
  ) {
    return SupersetClient.post({
      endpoint: `/api/v1/sqllab/copilot_sql_conversation/`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json);
  }

  getDataConversationResponse(
      requestBody: any, // need to refactor to the type IConversationRequestBody when open the wukong data page
  ){
    return SupersetClient.post({
      endpoint: `/api/v1/sqllab/copilot_data_conversation/`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json);
  }

  executeSqlFromWukong(sql: string): Promise<{ text: string }> {
    const databaseIds = this.allDatabaseIds;
    if (databaseIds.length <= 0) {
    return Promise.reject(new Error('No database found for current user.'));
    }

    let dbIndex = 0;

    const executeSqlWithRetry: () => Promise<{ text: string }> = () => {
      const postPayload = {
        client_id: '',
        database_id: databaseIds[dbIndex],
        json: true,
        runAsync: false,
        schema: null,
        sql,
        sql_editor_id: '1',
        tab: '',
        tmp_table_name: '',
        select_as_cta: false,
        ctas_method: 'TABLE',
        templateParams: '',
        queryLimit: 1000,
        expand_data: true,
      };
      return SupersetClient.post({
        endpoint: `/api/v1/sqllab/execute/`,
        body: JSON.stringify(postPayload),
        headers: { 'Content-Type': 'application/json' },
        parseMethod: 'text',
      }).catch(e => {
        if (e instanceof Response) {
        return e.json().then((response) => {
            if (response.errors && response.errors[0] && response.errors[0]?.error_type === 'GENERIC_DB_ENGINE_ERROR') {
            // current database is not the expected one
            if (dbIndex < databaseIds.length - 1) {
                dbIndex += 1;
                return executeSqlWithRetry();
            }
            return Promise.reject(new Error('The selected sql cannot be run on any of your database.'));
            }
            if (response.error) {
            return Promise.reject(new Error(response.error));
            }
            if (databaseIds[dbIndex] === 25 && dbIndex < databaseIds.length - 1) {
            dbIndex += 1;
            return executeSqlWithRetry();
            }
            return Promise.reject(new Error(`Unexpected execution results: ${response.message}`));
        });
        }
        return Promise.reject(new Error(`Unexpected execution results.`));
      });
    };
    return executeSqlWithRetry();
  }
}