import WukongClientClass from './WukongClientClass';
import { WukongClientInterface } from './types';

let singletonClient: WukongClientClass | undefined;

function getInstance(): WukongClientClass {
  if (!singletonClient) {
    throw new Error(
      'You must call WukongClient.configure(...) before calling other methods',
    );
  }
  return singletonClient;
}

const WukongClient: WukongClientInterface = {
  configure: config => {
    singletonClient = new WukongClientClass(config);
  },
  reset: () => {
    singletonClient = undefined;
  },
  getConversationResponse: requestBody =>
    getInstance().getConversationResponse(requestBody),
  getDataConversationResponse: requestBody =>
    getInstance().getDataConversationResponse(requestBody),
  generate: requestBody => getInstance().generate(requestBody),
  refactor: requestBody => getInstance().refactor(requestBody),
  executeSqlFromWukong: sql => getInstance().executeSqlFromWukong(sql),
};

export default WukongClient;
